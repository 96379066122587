
import React from 'react'
import "./pricing.css"
const Pricing = () => {
  return (
    <div>
      pricing
    </div>
  )
}

export default Pricing
